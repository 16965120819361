import { LOGS_ACTIVITY_STATS } from "../../constant/ActionType";

export default function AllLogsActivitiesStats(
  state = {
    allActivitiesstatslogs: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case LOGS_ACTIVITY_STATS:
      return {
        ...state,
        allActivitiesstatslogs: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}

import React, { useState, useEffect, memo } from 'react';
import { Form, Input, Select, Card, Button, Space, Row, Col, Modal, Spin } from 'antd';
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  Phone,
  Globe,
  Plus,
  X
} from 'lucide-react';
import { TbVariablePlus } from "react-icons/tb";
import { Button as ButtonMui } from "@mui/material";
import { dispatch } from "store/index";
import { AddWhatsappTemplate, FetchWhatsappChannel, FetchCreateWhatsAppSetting, PostUrlHeader } from "store/action/index";
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from "react-router-dom";

const { TextArea } = Input;
const { Option } = Select;

const MediaPreview = memo(({ path }) => {
  if (!path) return null;
  return <embed src={path} width="100%" height="300px" />;
});

const WhatsAppPreview = memo(({ preview, buttons, path }) => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg">
      {preview.headerText && (
        <div className="font-bold text-gray-800 mb-2">
          Character Count: {
            (preview?.headerText?.length || 0) +
            (preview?.text?.length || 0) +
            (preview?.footerText?.length || 0)
          }
        </div>
      )}
      <div className="bg-white rounded-lg shadow-sm p-4 space-y-4">
        {preview.headerText && (
          <div className="font-bold text-gray-800">{preview.headerText}</div>
        )}

        <MediaPreview path={path} />

        {preview.text && (
          <div className="text-gray-700 whitespace-pre-wrap">{preview.text}</div>
        )}

        {preview.footerText && (
          <div className="text-sm text-gray-500">{preview.footerText}</div>
        )}

        {buttons.length > 0 && (
          <div className="space-y-2 d-flex flex-column pt-2 border-t">
            {buttons.map((button) => (
              <Button
                key={button.id}
                className="w-full bg-blue-600 m-1 rounded"
              >
                {button.text || `New ${button.type} Button`}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

const CreateWhatsappTemplate = () => {
  const [form] = Form.useForm();
  const [buttons, setButtons] = useState([]);
  const [preview, setPreview] = useState({
    headerText: '',
    text: '',
    footerText: '',
    buttons: []
  });
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [documentloading, setDocumentLoading] = useState(false);
  const [variableCount, setVariableCount] = useState(0);
  const [variableValues, setVariableValues] = useState([]);
  const [templateValues, setTemplateValues] = useState(null);
  const [hasFilledVariables, setHasFilledVariables] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [selectCate, setSelectCate] = useState('')
  const navigate = useNavigate()
  const selector = useSelector((state) => state);
  const AllchannelData = selector?.AllWhatsappChannnel?.allwhatsappchannel ?? [];
  const [loadingBtn, setLoadingBtn] = useState(false)
  const activeChannels = AllchannelData?.filter(channel => channel.active == 1);
  const whatsappSettingData = selector?.AllWhatsappSettingReducer?.allWhatsappsetting?.data ?? [];
  const urlsenderTemplatess = selector?.UrlReducer?.urlsenderTemplates?.data ?? [];

  const buttonTypes = [
    { key: 'URL', icon: <Globe style={{ width: '14px', marginRight: '5px' }} />, label: 'Website' },
    { key: 'PHONE', icon: <Phone style={{ width: '14px', marginRight: '5px' }} />, label: 'Call Us' },
  ];

  useEffect(() => {
    dispatch(FetchCreateWhatsAppSetting({ setLoading: setIsLoading }));
    dispatch(FetchWhatsappChannel())
  }, []);

  useEffect(() => {
    if (Array.isArray(whatsappSettingData) && whatsappSettingData.length > 0) {
      const defaultProviderId = whatsappSettingData[0].id;
      handleProviderSelect(defaultProviderId);
      form.setFieldsValue({
        Provider: defaultProviderId
      });
    }
  }, [whatsappSettingData]);

  useEffect(() => {
    if (selectedChannel) {
      form.setFieldsValue({
        channelType: selectedChannel.type,
        subjectId: selectedChannel.subjectId,
        subject: selectedChannel.subject
      });
    }

    if (selectCate) {
      form.setFieldsValue({
        category: selectCate,
      });
    }
  }, [selectedChannel, selectCate]);

  useEffect(() => {
    updatePreview(form.getFieldsValue());
  }, [buttons])

  const handleChannelSelect = (channelId) => {
    const selectedChannel = activeChannels?.find(channel => channel.id === channelId);
    setSelectedChannel(selectedChannel);
  };
  const handleProviderSelect = (pro_id) => {
    setSelectedProvider(pro_id)
  }

  const handleCategorySelect = (pro_id) => {
    setSelectCate(pro_id)
  }

  const handleHeaderTypeSelect = (value) => {
    if (value) {
      form.setFieldsValue({
        header_type: value,
      });
    }
  }

  const handleNavigate = () => {
    navigate('/templates/whatsapp-templates')
  }

  const countVariables = (text) => {
    const matches = text.match(/\{\{\d+\}\}/g);
    if (!matches) return 0;
    const uniqueVariables = new Set(
      matches.map(match => match.match(/\d+/)[0])
    );
    return uniqueVariables.size;
  };

  const updatePreview = debounce((values) => {
    setPreview((prev) => ({
      ...prev,
      ...(values.headerText && { headerText: values.headerText }),
      ...(values.attachment && { attachment: values.attachment }),
      ...(values.text && { text: values.text }),
      ...(values.footerText && { footerText: values.footerText }),
      buttons: buttons
    }));
  }, 30);

  const addButton = (type) => {
    const newButton = {
      id: buttons.length,
      type,
      text: '',
      value: ''
    };
    setButtons([...buttons, newButton]);
  };

  const removeButton = (buttonId) => {
    setButtons(buttons.filter(button => button.id !== buttonId));
    updatePreview(form.getFieldsValue());
  };

  const updateButtonField = (buttonId, field, value) => {
    const updatedButtons = buttons.map(button =>
      button.id === buttonId ? { ...button, [field]: value } : button
    );
    setButtons(updatedButtons);
    updatePreview(form.getFieldsValue());
  };

  const handleVariableModalOk = () => {
    if (variableValues.every(value => value)) {
      setHasFilledVariables(true);
      const matcherFormat = transformToMatcherFormat(templateValues, variableValues);
      setTemplateValues(matcherFormat);
      setIsVariableModalOpen(false);
      setIsLoading(true)
      dispatch(AddWhatsappTemplate({
        matcherFormat: { ...matcherFormat, provider_id: selectedProvider },
        handleNavigate: handleNavigate,
        setIsLoading: setIsLoading
      }));
    } else {
      alert("Please fill in all variable values");
    }
  };

  const handleVariableModalCancel = () => {
    setIsVariableModalOpen(false);
  };

  const transformToMatcherFormat = (values, textExampleParams = []) => {
    const formattedButtons = buttons.map(button => {
      const baseButton = {
        text: button.text,
        buttonType: button.type
      };

      if (button.type === 'URL') {
        return {
          ...baseButton,
          url: button.value,
          urlTextExample: button.value
        };
      } else {
        return {
          ...baseButton,
          phone: button.value
        };
      }
    });
    return {
      messageMatcher: {
        name: values.name,
        channelType: values.channelType,
        language: "EN",
        content: {
          header: values?.header_type ? {
            headerType: values?.header_type,
            // attachment: { fileUrl: urlsenderTemplatess?.path, id: 0, size: 0, originalFileName: "screen-capture.mp4", },
            text: values.headerText,
            headerExampleMediaUrl: urlsenderTemplatess?.path
          } : undefined,
          text: values.text,
          textExampleParams: textExampleParams,
          keyboard: buttons.length > 0 ? {
            rows: [{
              buttons: formattedButtons
            }]
          } : undefined,
          footer: values.footerText ? {
            text: values.footerText
          } : undefined
        },
        category: values?.category,
        type: "OPERATOR",
        subject: values.subject,
      },
      subjectIds: [values.subjectId]
    };
  };

  const onFinish = (values) => {
    const count = countVariables(values.text || '');
    if (count > 0 || !hasFilledVariables) {
      setVariableCount(count);
      setVariableValues(new Array(count).fill(''));
      setTemplateValues(values);
      setIsVariableModalOpen(true);
    } else {
      setIsLoading(true)
      setLoadingBtn(true)
      dispatch(AddWhatsappTemplate({
        matcherFormat: hasFilledVariables ? { ...templateValues, provider_id: selectedProvider } : { ...transformToMatcherFormat(values), provider_id: selectedProvider },
        handleNavigate: handleNavigate,
        setLoadingBtn: setLoadingBtn,
        setIsLoading: setIsLoading
      }));
    }
  };

  const handleAddVariable = () => {
    const bodyText = form.getFieldValue('text');
    const count = countVariables(bodyText || '');
    if (count <= 4) {
      if (!bodyText) {
        alert("Body Text cannot be empty.");
      } else {
        const matches = bodyText.match(/{{(\d+)}}/g);
        let nextNumber = 1;
        if (matches) {
          const numbers = matches.map(match => parseInt(match.match(/\d+/)[0]));
          nextNumber = Math.max(...numbers) + 1;
        }
        form.setFieldsValue({ text: `${bodyText} {{${nextNumber}}}` });
        updatePreview(form.getFieldsValue());
      }
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[+]?[1-9]\d{1,14}$/; // For E.164 format
    if (!phoneRegex.test(phoneNumber)) {

      return <div><p style={{ color: 'red', fontSize: '10px', marginLeft: '4px', marginTop: '5px' }} >Phone Number is not valid</p></div>
    } else {
      return <></>
    }
  };

  const handleImageUpload = async (file, onSuccess, onError) => {
    try {
      let headertyep = form.getFieldsValue("header_type")?.header_type
      if (headertyep !== "Text header") {
        let payloads = { header_type: headertyep, file: file }
        setDocumentLoading(true)
        dispatch(PostUrlHeader({ payloads: payloads, setDocumentLoading: setDocumentLoading }))
      }
      onSuccess();
    } catch (error) {
      setDocumentLoading(false)
      onError(error);
    }
  }

  const renderHeaderFields = () => {
    const headerType = form.getFieldValue('header_type');
    switch (headerType) {
      case 'TEXT':
        return (
          <Form.Item name="headerText" label="Header Text">
            <Input placeholder="Example: Your company {{1}}" />
          </Form.Item>

        );
      case 'IMAGE':
        return (
          <Spin spinning={documentloading}>
            <Form.Item name="attachment" label="Header Image">
              <Upload
                beforeUpload={(file) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    form.setFieldsValue({ attachment: e.target.result });
                    updatePreview({ ...form.getFieldsValue(), attachment: e.target.result });
                  };
                  reader.readAsDataURL(file);
                  return true;
                }}
                customRequest={({ file, onSuccess, onError }) =>
                  handleImageUpload(file, onSuccess, onError)
                }
                listType="picture"
                maxCount={1}
                accept=".jpeg,.jpg,.png"
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </Form.Item>
          </Spin>

        );
      case 'DOCUMENT':
        return (
          <Spin spinning={documentloading}>
            <Form.Item name="attachment" label="Header File">
              <Upload
                beforeUpload={(file) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    form.setFieldsValue({ attachment: e.target.result });
                    updatePreview({ ...form.getFieldsValue(), attachment: e.target.result });
                  };
                  reader.readAsDataURL(file);
                  return true;
                }}
                customRequest={({ file, onSuccess, onError }) =>
                  handleImageUpload(file, onSuccess, onError)
                }
                listType="text"
                maxCount={1}
                accept=".pdf"
              >
                <Button icon={<UploadOutlined />}>Upload File</Button>
              </Upload>
            </Form.Item>
          </Spin>
        );
      case 'VIDEO':
        return (
          <Spin spinning={documentloading}>
            <Form.Item name="attachment" label="Header Video">
              <Upload
                beforeUpload={(file) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    form.setFieldsValue({ attachment: e.target.result });
                    updatePreview({ ...form.getFieldsValue(), attachment: e.target.result });
                  };
                  reader.readAsDataURL(file);
                  return true;
                }}
                customRequest={({ file, onSuccess, onError }) =>
                  handleImageUpload(file, onSuccess, onError)
                }
                listType="text"
                maxCount={1}
                accept=".mp4,.3gp"            >
                <Button icon={<UploadOutlined />}>Upload Video</Button>
              </Upload>
            </Form.Item>
          </Spin>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Row gutter={[16, 24]} className="p-4">
          <Col xs={24} sm={14}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onValuesChange={updatePreview}
              initialValues={{
                channelType: 'WHATSAPP'
              }}
            >
              <Card className="mb-4">
                <Form.Item
                  name="name"
                  label="Template Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter template name" />
                </Form.Item>

                <Form.Item
                  name="channel"
                  label="Select Channel"
                  rules={[{ required: true, message: 'Please select a channel' }]}
                >
                  <Select
                    placeholder="Select a channel"
                    onChange={handleChannelSelect}
                  >
                    {Array.isArray(activeChannels) && activeChannels.length !== 0 && activeChannels?.map((channel) => (
                      <Option key={channel.id} value={channel.id}>
                        {channel.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="Provider"
                  label="Select Provider"
                  rules={[{ required: true, message: 'Please select a Provider' }]}
                >
                  <Select
                    placeholder="Select a Provider"
                    onChange={handleProviderSelect}
                    value={selectedProvider}
                  >
                    {Array.isArray(whatsappSettingData) && whatsappSettingData.length !== 0 && whatsappSettingData?.map((channel) => (
                      <Option key={channel.id} value={channel.id}>
                        {channel.sender_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>


                <Form.Item
                  name="category"
                  label="Select Category"
                  rules={[{ required: true, message: 'Please select a Category' }]}
                >
                  <Select
                    placeholder="Select a Category"
                    onChange={handleCategorySelect}
                  >
                    <Option value="UTILITY">Utility</Option>
                    <Option value="AUTHENTICATION">Authentication</Option>
                    <Option value="MARKETING">Marketing</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="header_type"
                  label="Select Header Type"
                  rules={[{ required: true, message: 'Please select a Header Type' }]}
                >
                  <Select
                    placeholder="Select a Header Type"
                    onChange={handleHeaderTypeSelect}
                  >
                    <Option value="TEXT">Text header</Option>
                    <Option value="IMAGE">Image header</Option>
                    <Option value="DOCUMENT">file header</Option>
                    <Option value="VIDEO">video header</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="channelType"
                  label="Channel Type"
                  hidden
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  name="subject"
                  label="Subject"
                  hidden
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  name="subjectId"
                  label="Subject ID"
                  hidden
                >
                  <Input disabled />
                </Form.Item>
              </Card>

              <Card title="Message Content" className="mb-4">
                {renderHeaderFields()}
                <Form.Item
                  name="text"
                  label="Body Text"
                  rules={[{ required: true }]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Example: Hello, {{1}}! Thanks for choosing {{2}}"
                  />
                </Form.Item>
                <ButtonMui
                  onClick={handleAddVariable}
                  title="Add Variable"
                  variant="contained"
                  className="my-2"
                >
                  <TbVariablePlus />
                </ButtonMui>

                <Form.Item name="footerText" label="Signature">
                  <Input placeholder="Add a footer note" />
                </Form.Item>
              </Card>

              <Card title="Buttons" className="mb-4">
                <div className="grid grid-cols-12 gap-2 mb-4 d-flex justify-content-center">
                  {buttonTypes.map(({ key, icon, label }) => (
                    <Button
                      key={key}
                      onClick={() => addButton(key)}
                      className="text-left grid-cols-12 flex items-center m-1 gap-4"
                      disabled={buttons.length >= 3}
                      style={{ width: '45%' }}
                    >
                      {icon}
                      <span className="pl-4">{label}</span>
                    </Button>
                  ))}
                </div>

                <Space direction="vertical" className="w-full">
                  {buttons.map((button) => (
                    <Card
                      key={button.id}
                      size="small"
                      className="w-full"
                      title={button.type === 'URL' ? "Link" : "Phone"}
                      extra={
                        <Button
                          type="text"
                          onClick={() => removeButton(button.id)}
                          className="p-0"
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      }
                    >
                      <div className="space-y-4">
                        <Input
                          placeholder="Button Text"
                          value={button.text}
                          onChange={(e) => updateButtonField(button.id, 'text', e.target.value)}
                        />

                        <Input
                          placeholder={button.type === 'URL' ? 'Website URL' : '+921234567890 (must be in this format)'}
                          value={button.value}
                          onChange={(e) => updateButtonField(button.id, 'value', e.target.value)}
                          className="mt-2"
                          type={button.type === 'URL' ? 'url' : 'text'}
                        />
                        {button.type === 'URL' ? <></> : validatePhoneNumber(button.value)}
                      </div>
                    </Card>
                  ))}
                </Space>
              </Card>

              <Button type="primary" htmlType="submit" className="w-full" loading={loadingBtn} >
                Save Template
              </Button>
            </Form>
          </Col>

          <Col xs={24} sm={10}>
            <Card title="Template Preview">
              <WhatsAppPreview
                preview={preview}
                buttons={buttons}
                path={urlsenderTemplatess?.path}
              />
            </Card>
          </Col>
        </Row>
      </Spin>

      <Modal
        title="Enter Variable Values"
        open={isVariableModalOpen}
        onOk={handleVariableModalOk}
        onCancel={handleVariableModalCancel}
      >
        <div className="space-y-4">
          <p>Found {variableCount} variables in your template. Please provide values for each:</p>
          {Array.from({ length: variableCount }).map((_, index) => (
            <Form.Item
              key={index}
              label={`Variable ${index + 1}`}
              className="mb-4"
            >
              <Input
                placeholder={`Enter value for variable ${index + 1}`}
                value={variableValues[index]}
                onChange={(e) => {
                  const newValues = [...variableValues];
                  newValues[index] = e.target.value;
                  setVariableValues(newValues);
                }}
              />
            </Form.Item>
          ))}
        </div>
      </Modal>
    </>

  );
};

export default CreateWhatsappTemplate;
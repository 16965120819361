import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

const NavigationBreadcrumb = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const getNavigationPath = () => {
        const path = location.pathname;
        const navigationMap = {
            '/campaigns/all-campaigns': { name: 'All Campaign', parent: null },
            '/templates/email-templates': { name: 'All Email Templates', parent: null },
            '/templates/whatsapp-templates': { name: 'All Whatsappp Templates', parent: null },
            '/templates/create-whatsapp-templates': { name: 'Create Whatsapp Templates', parent: '/templates/whatsapp-templates' },
            '/templates/channel-fetch': { name: 'Fetch Channels', parent: null },
            '/templates/cascade-fetch': { name: 'Fetch Cascade', parent: null },
            '/templates/sms-templates': { name: 'SMS Templates', parent: null },
            '/settings/all-email-setting': { name: 'All Email Settings', parent: null },
            '/settings/add-email-setting': { name: 'Create Email Setting', parent: '/settings/all-email-setting' },
            '/settings/all-whatsapp-setting': { name: 'All Whatsapp Settings', parent: null },
            '/settings/add-whatsapp-setting': { name: 'Create Whatsapp Settings', parent: '/settings/all-whatsapp-setting' },
            '/settings/all-sms-setting': { name: 'All SMS Settings', parent: null },
            '/settings/add-sms-setting': { name: 'Create SMS Setting', parent: '/settings/all-sms-setting' },
            '/settings/all-call-setting': { name: 'All Call Settings', parent: null },
            '/settings/add-call-setting': { name: 'Create Call Setting', parent: '/settings/all-call-setting' },
            '/teams/all-teams': { name: 'All Teams', parent: null },
            '/teams/add-team': { name: 'Create Teams', parent: '/teams/all-teams' },
            '/omnichannel/omni-channel': { name: 'Omni Channel', parent: null },
            '/call-script/all-call-script': { name: 'All Call Script', parent: null },
            '/activities/call-manager': { name: 'Call Manager', parent: null },
            '/leads/all-leads': { name: 'All Leads', parent: null },
            '/leads/add-lead': { name: 'Create Lead', parent: '/leads/all-leads' },
            '/tags/all-tags': { name: 'All Tags', parent: null },
            '/all-logs/call-log': { name: 'All Call Logs', parent: null },
            '/all-logs/sms-log': { name: 'All SMS Logs', parent: null },
            '/all-logs/email-log': { name: 'All Email Logs', parent: null },
            '/all-logs/whatsapp-log': { name: 'All Whatsapp Logs', parent: null },
            '/all-logs/robo-call-log': { name: 'All Robo Call Logs', parent: null },
            '/permissions/all-permissions': { name: 'Assign Role Permissions', parent: null },
            '/users/all-users': { name: 'All Users', parent: null },
            '/users/register-users': { name: 'Create User', parent: '/users/all-users' },
        };

        const currentRoute = navigationMap[path];
        if (!currentRoute) return null;

        return {
            parent: currentRoute.parent,
            current: currentRoute.name
        };
    };

    const handleNavigate = (route) => {
        if (route) {
            navigate(route); // Navigate to the parent route
        }
    };
    const handleNavigateHome = (route) => {
        navigate("/dashboard"); // Navigate to the parent route
    };

    const navigationPath = getNavigationPath();

    if (!navigationPath) return null;

    return (
        <div>
            <h1
                style={{
                    fontSize: "13px",
                    marginLeft: "11px",
                    marginBottom: "10px",
                    marginTop: "0px",
                    fontFamily: "poppins",
                    color: "rgb(133 134 135)",
                }}
            >
                {navigationPath.parent ? (
                    <>
                        <span style={{ cursor: "pointer" }} onClick={() => handleNavigateHome()}
                        >Home</span>
                        <span className="mx-2 text-gray-500">&gt;</span>
                        <span
                            style={{
                                textDecoration: 'none',
                                cursor: 'pointer',
                                textTransform: 'capitalize',
                            }}
                            className="text-gray-500"
                            onClick={() => handleNavigate(navigationPath.parent)}
                        >
                            {navigationPath.parent.split('/').pop().replace(/-/g, ' ')} {/* Added replace to remove dashes */}
                        </span>
                        <span className="mx-2 text-gray-500">&gt;</span>
                        <span>{navigationPath.current}</span>
                    </>
                ) : (
                    <>
                        <span style={{ cursor: "pointer" }} onClick={() => handleNavigateHome()}
                        >Home</span>
                        <span className="mx-2 text-gray-500">&gt;</span>
                        <span> {navigationPath.current}</span>
                    </>

                )}
            </h1>
        </div>
    );
};

export default NavigationBreadcrumb;

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

const checkPermissions = (item_name) => {
  let permissionUser = localStorage.getItem("data");

  let decryptedData = null;
  if (permissionUser) {
    try {
      // Code that may throw an exception
      decryptedData = AES.decrypt(
        permissionUser,
        url.encryptionSecret
      ).toString(enc.Utf8);
      // ...
    } catch (error) {
      localStorage.clear();
      window.location.reload();
    }
  }
  let permissionsArray = [];

  try {
    permissionsArray = decryptedData && JSON.parse(decryptedData)?.permissions;
  } catch (e) { }
  // const isAllow = permissionsArray?.includes(item_name);
  const isAllow = permissionsArray?.includes(item_name) || false;
  // return true
  return isAllow;
};

//___________________________ALKHIDMAT------ALKHIDMAT------ALKHIDMAT-------------------------------------------

// <---- roles permission ---------->
export const can_add_roles = checkPermissions("add-roles");
export const can_edit_roles = checkPermissions("edit-roles");
export const can_delete_roles = checkPermissions("delete-roles");
export const can_view_roles = checkPermissions("view-roles");
// <----roles  permission ---------->
// <----  permission ---------->
export const can_add_permissions = checkPermissions("add-permissions");
export const can_view_permission = checkPermissions("view-permissions");
export const can_edit_permission = checkPermissions("edit-permissions");
export const can_delete_permission = checkPermissions("delete-permissions");
// <----  permission ---------->
// <---- Users permission ---------->
export const can_add_users = checkPermissions("add-users");
export const can_view_users = checkPermissions("view-users");
export const can_edit_users = checkPermissions("edit-users");
export const can_delete_users = checkPermissions("delete-users");
// <---- Users permission ---------->

// -------- TEAMS --------------
export const can_add_teams = checkPermissions("add-teams");
export const can_view_teams = checkPermissions("view-teams");
export const can_edit_teams = checkPermissions("edit-teams");
export const can_delete_teams = checkPermissions("delete-teams");
// -------- TEAMS --------------
// -------- ACTIVITIES --------------
export const can_add_activities = checkPermissions("add-activities");
export const can_view_activities = checkPermissions("view-activities");
export const can_edit_activities = checkPermissions("edit-activities");
// -------- ACTIVITIES --------------
// -------- CAMPAIGN --------------
export const can_add_campaign = checkPermissions("add-campaign");
export const can_view_campaign_activitiess = checkPermissions("campaign-activities");

// -------- CAMPAIGN --------------
// <---- Templates ---------->
export const can_add_whatsapp_templates = checkPermissions(
  "add-whatsapp-template"
);
export const can_view_whatsapp_templates = checkPermissions(
  "view-whatsapp-template"
);

export const can_add_email_templates = checkPermissions("add-email-template");
export const can_view_email_templates = checkPermissions("view-email-template");
export const can_edit_email_templates = checkPermissions("edit-email-template");
export const can_delete_email_templates = checkPermissions(
  "delete-email-template"
);

export const can_add_sms_templates = checkPermissions("add-sms-template");
export const can_view_sms_templates = checkPermissions("view-sms-template");
export const can_edit_sms_templates = checkPermissions("edit-sms-template");
// <---- Templates ---------->

// ---------------------Setting---------------------------------
export const can_add_whatsapp_setting = checkPermissions(
  "add-whatsapp-setting"
);
export const can_add_call_setting = checkPermissions("add-call-setting");
export const can_add_sms_setting = checkPermissions("add-sms-setting");
export const can_add_email_setting = checkPermissions("add-email-setting");

export const can_view_whatsapp_setting = checkPermissions(
  "view-whatsapp-setting"
);
export const can_view_sms_setting = checkPermissions("view-sms-setting");
export const can_view_email_setting = checkPermissions("view-email-setting");
export const can_view_call_setting = checkPermissions("view-call-setting");

export const can_edit_call_setting = checkPermissions("edit-call-setting");
export const can_edit_email_setting = checkPermissions("edit-email-setting");
export const can_edit_sms_setting = checkPermissions("edit-sms-setting");
export const can_edit_whatsapp_setting = checkPermissions(
  "edit-whatsapp-setting"
);

// ---------------------Setting---------------------------------

// ---------------------Dashboard---------------------------------
export const can_view_call_made = checkPermissions("dashboard-call-made");
export const can_view_duration = checkPermissions("dashboard-duration");
export const can_view_followup = checkPermissions("dashboard-followup");
export const can_view_graph = checkPermissions("dashboard-graph");
export const can_view_piechart = checkPermissions("dashboard-piechart");
// ---------------------Dashboard---------------------------------

// ---------------------Leads---------------------------------

export const can_view_campaign_leads = checkPermissions("get-campaign-leads");
export const can_view_leads = checkPermissions("view-leads");
export const can_view_leads_details = checkPermissions("view-leads-details");
export const can_view_leads_history = checkPermissions("view-lead-history");

// ---------------------Leads--------------------------------- 

// ---------------------Scripts---------------------------------
export const can_add_call_scripts = checkPermissions("add-call-script");
export const can_edit_call_scripts = checkPermissions("add-call-script");
export const can_view_call_scripts = checkPermissions("view-call-script");

// ---------------------Scripts---------------------------------

// ---------------------members---------------------------------
export const can_view_members = checkPermissions("get-members");
// ---------------------members---------------------------------

// ---------------------members---------------------------------

export const can_view_email_progress = checkPermissions(
  "get-campaign-email-progress"
);
export const can_send_email_progress = checkPermissions("send-campaign-email");

// ---------------------members---------------------------------

// ---------------------tags---------------------------------
export const can_view_tags = checkPermissions("view-tags");
export const can_edit_tags = checkPermissions("edit-tags");
export const can_add_tags = checkPermissions("add-tags");
// ---------------------tags---------------------------------

// ---------------------calls---------------------------------
export const can_add_calls = checkPermissions("create-calls");
// ---------------------calls---------------------------------
// ---------------------logs---------------------------------
export const can_view_whatsapp_logs = checkPermissions("view-whatsapp-logs");
export const can_view_call_logs = checkPermissions("view-call-logs");
export const can_view_email_logs = checkPermissions("view-email-logs");
export const can_view_sms_logs = checkPermissions("view-sms-logs");
// ---------------------logs---------------------------------

// ---------------------call-manager---------------------------------
export const can_view_call_manager = checkPermissions("get-call-manager");
// ---------------------call-manager---------------------------------



// ---------------------omni-channel---------------------------------
export const can_view_omni_channel = checkPermissions("get-omni-channel ");
// ---------------------omni-channel---------------------------------


export const robo_call_log_permissions = checkPermissions("robo-call-logs");


// type
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import { HiUsers } from "react-icons/hi2";
import { MdOutlineSms } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import {
  can_view_whatsapp_logs,
  can_view_call_logs,
  can_view_email_logs,
  can_view_sms_logs,
  robo_call_log_permissions,
} from "helper/permissionsCheck";
import DrawerRight from "components/drawer/AddCampaignDrawer";
import { Typography } from "@mui/material";
import { CgDetailsMore } from "react-icons/cg";
// icons
const icons = {
  navigation: MdHome,
  flatType: TbHomeCog,
  amenities: BsBuildingFillAdd,
  flatSize: TbRulerMeasure,
  taxes: HiOutlineReceiptTax,
  flat: MdOutlineBedroomParent,
  assignPermission: MdOutlineSecurity,
  color: MdHome,
  shadow: MdHome,
  AllUsers: HiUsers,
  calllogs: IoIosCall,
  smslogs: MdOutlineSms,
  emaillogs: MdEmail,
  whatsapp: FaWhatsapp,
  logs: CgDetailsMore,
};

const allLogs =
  can_view_whatsapp_logs ||
    can_view_call_logs ||
    can_view_email_logs ||
    can_view_sms_logs
    ? {
      id: "all-logs",
      title: "Logs",
      icon: icons.logs,
      type: "group",
      children: [
        can_view_call_logs
          ? {
            id: "call-logs",
            title: "Call Logs",
            type: "item",
            url: "/all-logs/call-log",
            icon: icons.calllogs,
            breadcrumbs: true,
          }
          : null,
        can_view_sms_logs
          ? {
            id: "sms-logs",
            title: "SMS Logs",
            type: "item",
            url: "/all-logs/sms-log",
            icon: icons.smslogs,
            breadcrumbs: true,
          }
          : null,
        can_view_email_logs
          ? {
            id: "email-logs",
            title: "Email Logs",
            type: "item",
            url: "/all-logs/email-log",
            icon: icons.emaillogs,
            breadcrumbs: true,
          }
          : null,
        can_view_whatsapp_logs
          ? {
            id: "whatsapp-logs",
            title: "WhatsApp Logs",
            type: "item",
            url: "/all-logs/whatsapp-log",
            icon: icons.whatsapp,
            breadcrumbs: true,
          }
          : null,
        robo_call_log_permissions
          ? {
            id: "rob-calls-logs",
            title: "Robo Call Logs",
            type: "item",
            url: "/all-logs/robo-call-log",
            icon: icons.whatsapp,
            breadcrumbs: true,
          }
          : null,
      ].filter(Boolean),
    }
    : null;

export default allLogs;

import { useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
import ComponentsRoutes from './ComponentsRoutes';
import MainRoutes from './MainRoutes';
import Pusher from 'pusher-js';
import { dispatch } from "../store/index";
import { FetchAllCompaigns, FetchCheckUserOnSession } from "../store/action/index";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import Echo from "laravel-echo";

export default function ThemeRoutes() {
  const user = localStorage.getItem("token");
  // window.Pusher = Pusher;

  // const echo = new Echo({
  //   cluster: "mt1",
  //   broadcaster: "pusher",
  //   key: "local_key",
  //   wsHost: window.location.hostname,
  //   wsPort: 6001,
  //   forceTLS: false,
  //   disableStats: true,
  // });

  // useEffect(() => {
  //   if (user && user !== "" && user !== null) {

  //     let permissionUser = localStorage.getItem("data");

  //     let decryptedData = null;
  //     if (permissionUser) {
  //       try {
  //         decryptedData = AES.decrypt(
  //           permissionUser,
  //           url.encryptionSecret
  //         ).toString(enc.Utf8);
  //       } catch (error) {
  //         localStorage.clear();
  //         window.location.reload();
  //       }
  //     }

  //     const permissionAll = decryptedData && JSON.parse(decryptedData);
  //     const channel = echo.channel("my-event");

  //     channel.listen(".my-channel", (data) => {
  //       if (permissionAll?.user_type == "agent" && data?.message == "refreshAgent") {
  //         dispatch(FetchCheckUserOnSession());
  //       }
  //     });

  //     return () => {
  //       echo.leave("my-event");
  //     };
  //   }
  // }, [user]);

  return useRoutes([user && user !== "" && user !== null ? MainRoutes : ComponentsRoutes]);
}
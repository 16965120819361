import { ALL_CAMPAIGN_REPORT } from "../../constant/ActionType";

export default function AllOverAllCampaignREport(
    state = {
        allreportcampaign: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case ALL_CAMPAIGN_REPORT:
            return {
                ...state,
                allreportcampaign: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}

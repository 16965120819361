import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Pagination } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { dispatch } from "store/index";
import { compaignsColumns } from "components/columns/columns";
import { FetchAllCompaigns, FetchAllCampaignReports } from "store/action/index";
import DrawerRight from "../../components/drawer/AddCampaignDrawer";
import * as XLSX from "xlsx";
import { CiExport } from "react-icons/ci";
import { IoAdd } from "react-icons/io5";

const AllCampaigns = ({ allcompaigns, allreportcampaign }) => {
  const [loading, setLoading] = useState(true);
  const [excelLoading, setExcelLoading] = useState(true);
  const [params, setparams] = useState({ page: 1 });
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(FetchAllCompaigns({ setLoading: setLoading, params: params }));
  }, [params]);

  useEffect(() => {
    dispatch(FetchAllCampaignReports({ setExcelLoading: setExcelLoading }));
  }, [])

  const handleNavigate = (record) => {
    navigate("/activities/all-activities", { state: record });
  };

  const columns = compaignsColumns({ params, setparams, handleNavigate });

  const handlePageChange = (page) => {
    setparams({ ...params, page });
  };

  const exportExcel = () => {
    const formattedData = (Array.isArray(allreportcampaign) ? allreportcampaign : []).map((log) => ({
      "Agent Name": log?.agent_name || "",
      "Campaigns": log?.total_campaigns || "",
      "Activities": log?.total_activity_settings || "",
      "Call Attempted": log?.attempted_count || "",
      "Calls Connected": log?.connected_calls || "",
      "Total Duration Min": log?.total_duration || "",
      "Avg Duration per Call": log?.avg_duration || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SmsLogs");
    XLSX.writeFile(workbook, "AgentStatsCmapignOverallReport.xlsx");
  };

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  All Campaigns
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <div
                style={{ display: "flex", justifyContent: "right", padding: "10px" }}
              >
                <Button
                  disableElevation
                  className="px-2"
                  size="large"
                  type="submit"
                  onClick={exportExcel}
                >
                  {excelLoading ? <span>Exporting...</span> : <> <span className="mx-2">
                    <CiExport color="#0055A2" size={26} />
                  </span>
                    Agent Stats Campaign Overall</>}
                </Button>
              </div>
              <Grid item>
                <DrawerRight>
                  <Button
                    disableElevation
                    className="px-2"
                    size="large"
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoAdd color="#0055A2" size={26} />
                    </span>
                    Add Campaign
                  </Button>
                </DrawerRight>
              </Grid>

            </Grid>
          </div>
        </MainCard>
      </div>

      <MainCard>
        <div style={{ overflowX: "auto" }}>
          <Table
            dataSource={allcompaigns?.data ?? []}
            columns={columns}
            pagination={false}
            loading={loading}
            rowKey="id"
          />
        </div>
        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Pagination
            current={allcompaigns?.meta?.current_page || 1}
            total={allcompaigns?.meta?.total || 0}
            pageSize={allcompaigns?.meta?.per_page || 10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allcompaigns: state?.AllCompainReducer?.allcompaigns,
    allreportcampaign: state?.AllOverAllCampaignREport?.allreportcampaign,
  };
};

export default connect(mapStateToProps)(AllCampaigns);

import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Modal } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import AddActivityDrawerRight from "components/drawer/AddActivityDrawer";
import { EditOutlined } from "@ant-design/icons";
import { dispatch } from "store/index";
import { FetchAllActivitiesOfCamp, ExportCampaignActivityReport, GetLogsActivityStats, CreateBulkUploader } from "store/action/index";
import * as XLSX from "xlsx";
import { IoIosCall } from "react-icons/io";
import { CiExport } from "react-icons/ci";
import { MdOutlineSms } from "react-icons/md";
import moment from "moment";
import { MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { TbEyeCog } from "react-icons/tb";
import { FaRobot } from "react-icons/fa6";
import {
  can_add_activities,
  can_view_activities,
  can_edit_activities,
} from "helper/permissionsCheck";
import CsvUploadByofllineCall from '../../components/inputs/csvUploadOfflineCall'

const AllCampaigns = ({ allActivities, campaignActExpData, allActivitiesstatslogs }) => {
  const [loading, setLoading] = useState(true);
  const [loadingE, setELoading] = useState(true);
  const [campaignSeetinId, setCampaig_seeting_Id] = useState(null);
  const [params, setparams] = useState({ page: 1, activities_id: 2 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [excelLoading, setExcelLoading] = useState(true);
  const [excelSLoading, setExcelSLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const activityRoutes = {
    SMS: '/activities/edit-sms-activity',
    Call: '/activities/edit-call-activity',
    Email: '/activities/edit-email-activity',
    WhatsApp: '/activities/edit-whatsapp-activity',
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          {record?.activities?.id == 2 ? (
            <IoIosCall style={{ fontSize: "22px" }} />
          ) : record?.activities?.id == 3 ? (
            <FaWhatsapp style={{ fontSize: "22px" }} />
          ) : record?.activities?.id === 5 ? (
            <MdEmail style={{ fontSize: "22px" }} />
          ) : record?.activities?.id == 4 ? (
            <MdOutlineSms style={{ fontSize: "22px" }} />
          ) : record?.activities?.id == 6 ? (
            <FaRobot style={{ fontSize: "22px" }} />) : (
            ""
          )}
        </span>
      ),
    },
    can_edit_activities || can_view_activities ? {
      title: "Actions",
      key: "action",
      render: (text, record) => {
        console.log("Record Data:", record);
        return (
          <Button
            type="text"
            style={{ color: "#1890ff" }}
            onClick={() => handleEdit(record)}
          >
            {record?.status == 4 ? (
              <TbEyeCog style={{ fontSize: "18px" }} />
            ) : record?.status !== 1 ? (
              <EditOutlined style={{ fontSize: "18px" }} />
            ) : (
              <TbEyeCog style={{ fontSize: "18px" }} />
            )}
          </Button>
        );
      },
    } : null
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setELoading(true);
    const transformedDataSource = dataSource.map(item => ({
      ...item,
      date_time: moment(item.date_time, 'M/D/YYYY H:mm').format('YYYY-MM-DD HH:mm:ss'),
      activity_setting_id: campaignSeetinId
    }));
    let fieldDataFormat = { data: transformedDataSource };
    dispatch(CreateBulkUploader({ fieldData: fieldDataFormat, setELoading: setELoading }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEdit = (record) => {
    console.log("recordcheckaccc", record)
    if (record?.activity_id == "6") {
      setCampaig_seeting_Id(record?.id)
      showModal();
      return;
    }
    navigate(activityRoutes[record?.activities?.name], {
      state: {
        data: {
          ...location?.state,
          ...record,
          ...allActivities,
          activity_id_specific: record?.id,
          records: record,
        },
      },
    });
  };

  const handlePageChange = (page) => {
    setparams({ ...params, page });
  };

  useEffect(() => {
    dispatch(FetchAllActivitiesOfCamp({ id: location.state?.id, setLoading: setLoading }));
    dispatch(ExportCampaignActivityReport({ setExcelLoading: setExcelLoading, id: location?.state?.id }));
    dispatch(GetLogsActivityStats({ setExcelSLoading: setExcelSLoading }));
  }, []);

  useEffect(() => { console.log("locationssssss", allActivities) }, [allActivities])

  const exportExcel = () => {
    const formattedData = (Array.isArray(campaignActExpData?.data) ? campaignActExpData?.data : []).map((log) => ({
      "Agent Name": log?.agent_name || "",
      "Call Attempted": log?.attempted_count || "",
      "Calls Connected": log?.connected_calls || "",
      "Total Duration Min": log?.total_duration || "",
      "Avg Duration per Call": log?.avg_duration || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SmsLogs");
    XLSX.writeFile(workbook, "AgentStatsCmapignReport.xlsx");
  };

  const exportLExcel = () => {
    const formattedData = (Array.isArray(allActivitiesstatslogs) ? allActivitiesstatslogs : []).map((log) => ({
      "Activity Name": log?.activity_name || "",
      "Total Batches": log?.total_batches || "",
      "Unique Leads": log?.total_leads || "",
      "Activities": log?.executed || "",
      "Connected": log?.connected || "",
      "Responded": log?.connected || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SmsLogs");
    XLSX.writeFile(workbook, "AgentStatsCmapignReport.xlsx");
  };

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  {location.state?.name}
                </span>
                <p
                  style={{
                    fontSize: "10px",
                    padding: "0px",
                    margin: "0px",
                    marginLeft: "4px",
                  }}
                >
                  {location.state?.start_date}
                </p>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <div
                style={{ display: "flex", justifyContent: "right", padding: "10px" }}
              >
                <Button
                  disableElevation
                  className="px-2"
                  size="large"
                  type="submit"
                  onClick={exportLExcel}
                >
                  {excelSLoading ? <span>Exporting...</span> : <> <span className="mx-2">
                    <CiExport color="#0055A2" size={26} />
                  </span>
                    Activities Stats Report</>}
                </Button>
              </div>
              <div
                style={{ display: "flex", justifyContent: "right", padding: "10px" }}
              >
                <Button
                  disableElevation
                  className="px-2"
                  size="large"
                  type="submit"
                  onClick={exportExcel}
                >
                  {excelLoading ? <span>Exporting...</span> : <> <span className="mx-2">
                    <CiExport color="#0055A2" size={26} />
                  </span>
                    Agent Stats Campaign</>}
                </Button>
              </div>
              <Grid item>
                {can_add_activities ? <AddActivityDrawerRight data={location?.state}>
                  <Button
                    disableElevation
                    className="px-2"
                    size="large"
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoMdAddCircle color="#0055A2" size={26} />
                    </span>
                    Add Activity
                  </Button>
                </AddActivityDrawerRight> : <></>}
              </Grid>
            </Grid>
          </div>
        </MainCard>
      </div>

      <>
        <MainCard>
          {can_view_activities ? (
            <Spin spinning={loading}>
              <Table
                className="border rounded"
                style={{ marginTop: 25 }}
                dataSource={allActivities?.campaings_activities ?? []}
                columns={columns}
              />
            </Spin>
          ) : null}
        </MainCard>
      </>
      <Modal
        title="Basic Modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CsvUploadByofllineCall
          setDataSource={setDataSource}
        />

      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allActivities: state?.CampaignAllActivitiesRedc?.campaignAllActivities,
    campaignActExpData: state?.CampActivtyExportdataaRedc?.campaignActExpData,
    allActivitiesstatslogs: state?.AllLogsActivitiesStats?.allActivitiesstatslogs,
  };
};

export default connect(mapStateToProps)(AllCampaigns);

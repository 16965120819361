import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Pagination } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { dispatch } from "store/index";
import { callsLogColumns } from "components/columns/columns";
import { AllLogsCsvExport, FetchGetAllLogs } from "store/action/index";
import { CiExport } from "react-icons/ci";
import * as XLSX from "xlsx";

const SmsLogs = ({ alllogsdataaa, callogscsvdataaa }) => {
    const [loading, setLoading] = useState(true);
    const [params, setparams] = useState({ page: 1, activity_id: 4 });
    const [paramss, setparamss] = useState({ per_page: "all", activity_id: 4 });

    useEffect(() => {
        dispatch(AllLogsCsvExport({ paramss: paramss }));
    }, [])



    useEffect(() => {
        dispatch(FetchGetAllLogs({ params: params, setLoading: setLoading }));
    }, [params]);


    useEffect(() => {
        console.log("csvExpordsdsdst", callogscsvdataaa)
    }, [callogscsvdataaa]);

    const columns = callsLogColumns({ params, setparams });

    const handlePageChange = (page) => {
        setparams({ ...params, page });
    };


    useEffect(() => {
    }, [alllogsdataaa])

    const exportExcel = () => {
        const formattedData = (Array.isArray(callogscsvdataaa) ? callogscsvdataaa : []).map((log) => ({
            "Campaign Name": log?.campaign?.name || "",
            "Activity Name": log?.activity?.name || "",
            "Activity Action": log?.activity_action || "",
            "Lead Name": log?.lead?.name || "",
            "Lead Email": log?.lead?.email || "",
            "Lead Phone": log?.lead?.phone || "",
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SmsLogs");
        XLSX.writeFile(workbook, "SmsLogs.xlsx");
    };


    return (
        <>
            <MainCard>
                <div
                    style={{ display: "flex", justifyContent: "right", padding: "10px" }}
                >
                    <Button
                        disableElevation
                        className="px-2"
                        size="large"
                        type="submit"
                        onClick={exportExcel}

                    >
                        <span className="mx-2">
                            <CiExport color="#0055A2" size={26} />
                        </span>
                        Export Excel
                    </Button>
                </div>
                <div style={{ overflowX: "auto" }}>
                    <Table
                        dataSource={Array.isArray(alllogsdataaa?.data) && alllogsdataaa?.data || []}
                        columns={columns}
                        pagination={false}
                        loading={loading}
                        rowKey="id"
                    />
                </div>
                <div style={{ textAlign: "center", marginTop: 16 }}>
                    <Pagination
                        current={alllogsdataaa?.current_page || 1}
                        total={alllogsdataaa?.total || 0}
                        pageSize={alllogsdataaa?.per_page || 10}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                    />
                </div>
            </MainCard>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        alllogsdataaa: state?.GetAllLogsReducer?.alllogsdataaa,
        callogscsvdataaa: state?.GetCallLogsCsvReducer?.callogscsvdataaa,

    };
};

export default connect(mapStateToProps)(SmsLogs);
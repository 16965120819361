import React from "react";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";

const CsvUploadByofllineCall = ({ setDataSource }) => {
    return (
        <div>
            <h5 className="my-4">Upload CSV</h5>
            <Importer
                dataHandler={async (rows) => {
                    const formattedRows = rows.map((row, index) => ({
                        key: index,
                        name: row["Name"],
                        status: row["Status"],
                        date_time: row["DateTime"],
                        duration: row["Duration"],
                        question: row["Question"],
                        answer: row["Answer"],
                        phone_number: row["Phone"],
                        email: row["Email"],
                    }));
                    setDataSource(formattedRows.filter((v) => v.name));
                }}
            >
                <ImporterField name="Name" label="Name" />
                <ImporterField name="Status" label="Status" />
                <ImporterField name="DateTime" label="DateTime" />
                <ImporterField name="Duration" label="Duration" />
                <ImporterField name="Question" label="Question" />
                <ImporterField name="Answer" label="Answer" />
                <ImporterField name="Phone" label="Phone" />
                <ImporterField name="Email" label="Email" />
            </Importer>
        </div>
    );
};

export default CsvUploadByofllineCall;

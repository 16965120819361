import PropTypes from "prop-types";
import NavigationBreadcrumb from './breadcrumbcustom'

const Breadcrumbs = ({ navigation, title, sx, ...others }) => {
  return <NavigationBreadcrumb />;
};

Breadcrumbs.propTypes = {
  navigation: PropTypes.object,
  title: PropTypes.bool,
  sx: PropTypes.object,
  items: PropTypes.object,
  children: PropTypes.node,
};

export default Breadcrumbs;

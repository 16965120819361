import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Pagination, Button } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { dispatch } from "store/index";
import { allLeadsColumns } from "components/columns/columns";
import { FetchAllLeads, FetchAllDeatilsLeads, FetchAllLeadsReportOverAll } from "store/action/index";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DrawerLeadDetail from "./lead-detail";
import { IoAdd } from "react-icons/io5";
import {
  can_view_leads,
  can_view_leads_details,
  can_view_leads_history,
  an_view_campaign_leads,
} from "helper/permissionsCheck";
import * as XLSX from "xlsx";
import { CiExport } from "react-icons/ci";

const AllLeads = ({ allLeadsData, leadsoverallrepdata }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingD, setLoadingD] = useState(false);
  const [params, setParams] = useState({ page: 1 });
  const [excelLoading, setExcelLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(FetchAllLeads({ setLoading: setLoading, params: params }));
  }, [params]);

  useEffect(() => {
    let paramss = { per_page: "all" }
    dispatch(FetchAllLeadsReportOverAll({ setExcelLoading: setExcelLoading, params: paramss }));
  }, []);

  const columns = allLeadsColumns({ params, setParams });

  const HandleViewDetailsFunction = (record) => {
    setLoadingD(true);
    dispatch(
      FetchAllDeatilsLeads({ id: record?.id, setLoadingD: setLoadingD })
    );
  };

  const HandleEdit = (record) => {
    navigate("/leads/add-lead", { state: record });
  };

  if (can_view_leads_details) {
    columns.push({
      title: "Action",
      key: "actions",
      render: (text, record) => {
        return (
          <div style={{ display: "flex" }}>
            <DrawerLeadDetail loadingD={loadingD}>
              <Button
                type="primary"
                onClick={() => HandleViewDetailsFunction(record)}
              >
                View Details
              </Button>
            </DrawerLeadDetail>
            <Button
              onClick={() => HandleEdit(record)}>Edit</Button>
          </div>
        );
      },
    });
  }

  useEffect(() => { console.log("checkingalldataaaaaa", leadsoverallrepdata) }, [leadsoverallrepdata])

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const exportExcel = () => {
    let questions = leadsoverallrepdata?.question || [];

    const formattedData = (Array.isArray(leadsoverallrepdata?.leads) ? leadsoverallrepdata?.leads : []).map((log) => {
      let row = {
        "Lead Name": log?.name || "",
        "Phone": log?.phone || "",
        "Email": log?.email || "",
        "Call": log?.activities?.find(e => e.name == "Call")?.total_activities || "",
        "Whatsapp": log?.activities?.find(e => e.name == "WhatsApp")?.total_activities || "",
        "SMS": log?.activities?.find(e => e.name == "SMS")?.total_activities || "",
        "Email Activities": log?.activities?.find(e => e.name == "Email")?.total_activities || "",
      };

      questions.forEach((q) => {
        row[q.question] = "";
      });

      log.answers?.forEach(answerObj => {
        let matchedQuestion = questions.find(q => q.id == answerObj.question_id);
        if (matchedQuestion) {
          let answerValue = Array.isArray(answerObj.answer) ? answerObj.answer.join(", ") : answerObj.answer || "";
          console.log("checki greturn", answerValue)
          row[matchedQuestion.question] = answerValue;
        }
      });

      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SmsLogs");
    XLSX.writeFile(workbook, "AllLeadsReport.xlsx");
  };

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  All Leads
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <div
                style={{ display: "flex", justifyContent: "right", padding: "10px" }}
              >
                <Button
                  disableElevation
                  className="px-2"
                  size="large"
                  type="submit"
                  onClick={exportExcel}
                >
                  {excelLoading ? <span>Exporting...</span> : <> <span className="mx-2">
                    <CiExport color="#0055A2" size={26} />
                  </span>
                    All Leads Report</>}
                </Button>
              </div>
              <Grid item>
                <Link to={"/leads/add-lead"}>
                  <Button
                    disableElevation
                    className="px-2"
                    size="large"
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoAdd color="#0055A2" size={26} />
                    </span>
                    Add Lead
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </MainCard>
      </div>

      {can_view_leads ? <MainCard>
        <div style={{ overflowX: "auto" }}>
          <Table
            dataSource={allLeadsData?.data ?? []}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </div>

        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Pagination
            current={allLeadsData?.meta?.current_page || 1}
            total={allLeadsData?.meta?.total || 0}
            pageSize={allLeadsData?.meta?.per_page || 10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </MainCard> : <></>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allLeadsData: state?.AllLeadsReducer?.allLeads,
    leadsoverallrepdata: state?.OverAllLeadsReportsReducer?.leadsoverallrepdata,
  };
};

export default connect(mapStateToProps)(AllLeads);

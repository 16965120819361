import React, { useState, useEffect, memo } from 'react';
import { Form, Input, Select, Card, Button, Space, Row, Col, Modal, Spin, Upload } from 'antd';
import { useSelector } from "react-redux";
import {
    Phone,
    Globe,
    Plus,
    X
} from 'lucide-react';
import * as url from "../../store/constant/Endpoints";
import { Button as ButtonMui } from "@mui/material";
import { dispatch } from "store/index";
import { AddWhatsappTemplate, FetchWhatsappChannel, FetchAllWhatsappTemplates, PostUrlHeader } from "store/action/index";
import { UploadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
const CryptoJS = require("crypto-js");


const { TextArea } = Input;
const { Option } = Select;

const EditActivityWhatsappTemplate = ({ EditActivityTemplate, isDisabled, setWhatsappPlaceholder, whatsapp_placeholer, setHasFormBeenModified, isProgrammaticUpdate, selectedTemplate, setSelectedTemplate, MainbuttonCLicked, hasFormBeenModified, onTemplateCreated, setGetSeetingIGEre }) => {
    const [form] = Form.useForm();
    const [buttons, setButtons] = useState([]);
    const [preview, setPreview] = useState({
        headerText: '',
        text: '',
        footerText: '',
        buttons: []
    });
    const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
    const [documentloading, setDocumentLoading] = useState(false);
    const [selectCate, setSelectCate] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [selectedProviderId, setSelectedProviderId] = useState();
    const selector = useSelector((state) => state);
    const whatsappSettingData = selector?.AllWhatsappSettingReducer?.allWhatsappsetting?.data ?? [];
    const urlsenderTemplates = selector?.UrlReducer?.urlsenderTemplates?.data ?? false;
    const navigate = useNavigate();


    const buttonTypes = [
        { key: 'URL', icon: <Globe style={{ width: '14px', marginRight: '5px' }} />, label: 'Website' },
        { key: 'PHONE', icon: <Phone style={{ width: '14px', marginRight: '5px' }} />, label: 'Call Us' },
    ];

    useEffect(() => {
        dispatch(FetchWhatsappChannel());
        dispatch(FetchAllWhatsappTemplates()); // Assuming you have this action
    }, []);
    const placeholderRegex = /{{(.*?)}}/g; // Yeh regex sab placeholders ko match karega

    useEffect(() => {
        if (EditActivityTemplate) {
            form.setFieldsValue({
                name: EditActivityTemplate.name || '',
                category: EditActivityTemplate?.category || '',
                header_type: EditActivityTemplate?.header?.header_type || '',
                channelType: EditActivityTemplate.channelType || '',
                text: EditActivityTemplate.body || '',
                footerText: EditActivityTemplate.footer || '',
                headerText: EditActivityTemplate.header?.content || '',
                subject: EditActivityTemplate.subject || '',
                subjectId: EditActivityTemplate.subjectId || ''
            });

            if (EditActivityTemplate.content?.keyboard?.rows?.[0]?.buttons) {
                const existingButtons = EditActivityTemplate.content.keyboard.rows[0].buttons.map((button, index) => ({
                    id: index,
                    type: button.buttonType,
                    text: button.text,
                    value: button.buttonType === 'URL' ? button.url : button.phone
                }));
                setButtons(existingButtons);
            } else {
                setButtons([]);
            }
            updatePreview(form.getFieldsValue());

            if (EditActivityTemplate?.body?.match(placeholderRegex) && !isDisabled) {
                setIsVariableModalOpen(true)
            }
            console.log("checkckck", EditActivityTemplate?.body?.match(placeholderRegex))
        }

    }, [EditActivityTemplate]);

    // Update preview when buttons change
    useEffect(() => {
        updatePreview(form.getFieldsValue());
    }, [buttons]);

    useEffect(() => {
        if (Array.isArray(whatsappSettingData) && whatsappSettingData.length > 0) {
            const defaultProviderId = whatsappSettingData[0].id;
            setSelectedProviderId(defaultProviderId);
            form.setFieldsValue({
                provider_id: defaultProviderId
            });
        }
    }, [whatsappSettingData]);

    const handleNavigate = () => {
        navigate('/activities/all-activities');
    };

    const updatePreview = (values) => {
        setPreview((prev) => ({
            ...prev,
            ...(values.headerText && { headerText: values.headerText }),
            ...(values.text && { text: values.text }),
            ...(values.footerText && { footerText: values.footerText }),
            buttons: buttons
        }));
    };

    const addButton = (type) => {
        const newButton = {
            id: buttons.length,
            type,
            text: '',
            value: ''
        };
        setButtons([...buttons, newButton]);
    };

    useEffect(() => {
        if (selectCate) {
            form.setFieldsValue({
                category: selectCate,
            });
        }
    }, [selectCate]);

    const removeButton = (buttonId) => {
        setButtons(buttons.filter(button => button.id !== buttonId));
        updatePreview(form.getFieldsValue());
    };

    const updateButtonField = (buttonId, field, value) => {
        const updatedButtons = buttons.map(button =>
            button.id === buttonId ? { ...button, [field]: value } : button
        );
        setButtons(updatedButtons);
        updatePreview(form.getFieldsValue());
    };

    const transformToMatcherFormat = (values) => {
        const formattedButtons = buttons.map(button => {
            const baseButton = {
                text: button.text,
                buttonType: button.type
            };

            return button.type === 'URL'
                ? { ...baseButton, url: button.value, urlTextExample: button.value }
                : { ...baseButton, phone: button.value };
        });
        return {
            messageMatcher: {
                name: values.name,
                channelType: values.channelType,
                language: "EN",
                content: {
                    header: values?.header_type ? {
                        headerType: values?.header_type,
                        headerExampleMediaUrl: urlsenderTemplates?.path ? urlsenderTemplates?.path : EditActivityTemplate?.header?.headerExampleMediaUrl || '',
                        text: values.headerText,
                    } : undefined,
                    text: values.text,
                    keyboard: buttons.length > 0 ? {
                        rows: [{
                            buttons: formattedButtons
                        }]
                    } : undefined,
                    footer: values.footerText ? {
                        text: values.footerText
                    } : undefined
                },
                category: values?.category,
                type: "OPERATOR",
                subject: values.subject,
            },
            subjectIds: [values.subjectId]
        };
    };

    useEffect(() => {
        if (!selectedProviderId) {
            // toast.error("Provider must be selected");
            return;
        }
        setGetSeetingIGEre(selectedProviderId)
    }, [selectedProviderId])

    const createNewTemplate = async (values, variables = []) => {
        try {
            setIsLoading(true);
            if (!selectedProviderId) {
                toast.error("Provider must be selected");
                return;
            }
            const matcherFormat = { ...transformToMatcherFormat(values, variables), provider_id: selectedProviderId };
            console.log("payloadcheckonline", matcherFormat)
            let getNewTokenn = localStorage.getItem("token");
            let NewToekn = "";
            if (getNewTokenn) {
                NewToekn = CryptoJS.AES.decrypt(
                    getNewTokenn,
                    url.encryptionSecret
                ).toString(CryptoJS.enc.Utf8);
            }

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${NewToekn}`,
                }
            };

            const response = await axios.post(
                `${url?.base_url}${url.whatsapp_templates}`,
                matcherFormat,
                config
            );

            setIsLoading(false);
            const templateId = response?.data?.template_id;
            if (templateId && onTemplateCreated) {
                onTemplateCreated(templateId);
            }

            return templateId;
        } catch (error) {
            console.error('Error creating template:', error);
            setIsLoading(false);
            throw error;
        }
    };

    const handleVariableModalOk = () => {
        setIsVariableModalOpen(false);
    };

    const handleFormSubmission = async (values) => {
        try {
            setIsLoading(true);
            await createNewTemplate(values);
            if (onTemplateCreated) {
                onTemplateCreated();
            }
        } catch (error) {
            console.error('Error in form submission:', error);
            Modal.error({
                title: 'Error',
                content: 'Failed to create template. Please try again.'
            });
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (MainbuttonCLicked && hasFormBeenModified) {
            const currentValues = form.getFieldsValue();
            handleFormSubmission(currentValues).catch(error => {
                console.error('Error during form submission:', error);
            });
        }
    }, [MainbuttonCLicked, hasFormBeenModified]);

    // Return shouldNavigate status for parent component
    useEffect(() => {
        if (shouldNavigate) {
            handleNavigate();
        }
    }, [shouldNavigate]);

    const MediaPreview = memo(({ path }) => {
        if (!path) return null;
        return <embed src={path} width="100%" height="300px" />;
    });

    const WhatsAppPreview = memo(({ preview, buttons, path }) => (
        <div className="bg-gray-100 p-4 rounded-lg">
            {preview.headerText && (
                <div className="font-bold text-gray-800 mb-2">
                    Character Count: {(preview?.headerText?.length || 0) +
                        (preview?.text?.length || 0) +
                        (preview?.footerText?.length || 0)}
                </div>
            )}
            <div className="bg-white rounded-lg shadow-sm p-4 space-y-4">
                {preview.headerText && (
                    <div className="font-bold text-gray-800">{preview.headerText}</div>
                )}
                <MediaPreview path={path} />

                {preview.text && (
                    <div className="text-gray-700 whitespace-pre-wrap">{preview.text}</div>
                )}
                {preview.footerText && (
                    <div className="text-sm text-gray-500">{preview.footerText}</div>
                )}
                {buttons.length > 0 && (
                    <div className="space-y-2 d-flex flex-column pt-2 border-t">
                        {buttons.map((button) => (
                            <Button
                                key={button.id}
                                className="w-full bg-blue-600 m-1 rounded"
                            >
                                {button.text || `New ${button.type} Button`}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    ));

    const handleImageUpload = async (file, onSuccess, onError) => {
        try {
            let headertyep = form.getFieldsValue("header_type")?.header_type
            if (headertyep !== "Text header") {
                let payloads = { header_type: headertyep, file: file }
                setDocumentLoading(true)
                dispatch(PostUrlHeader({ payloads: payloads, setDocumentLoading: setDocumentLoading }))
            }
            onSuccess();
        } catch (error) {
            setDocumentLoading(false)
            onError(error);
        }
    }

    const renderHeaderFields = () => {
        const headerType = form.getFieldValue('header_type');
        const attachmentUrl = urlsenderTemplates?.path ? urlsenderTemplates?.path : EditActivityTemplate?.header?.headerExampleMediaUrl || '' // API se milne wala URL

        switch (headerType) {
            case 'TEXT':
                return (
                    <Form.Item name="headerText" label="Header Text">
                        <Input disabled={isDisabled}
                            placeholder="Example: Your company {{1}}" />
                    </Form.Item>

                );
            case 'IMAGE':
                return (
                    <Spin spinning={documentloading}>
                        <Form.Item label="Header Image">
                            <Upload
                                beforeUpload={(file) => {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        form.setFieldsValue({ attachment: e.target.result });
                                        updatePreview({ ...form.getFieldsValue(), attachment: e.target.result });
                                    };
                                    reader.readAsDataURL(file);
                                    return true;
                                }}
                                customRequest={({ file, onSuccess, onError }) =>
                                    handleImageUpload(file, onSuccess, onError)
                                }
                                listType="picture"
                                maxCount={1}
                                disabled={isDisabled}
                                accept=".jpeg,.jpg,.png"
                            >
                                <Button icon={<UploadOutlined />}>Upload Image</Button>
                            </Upload>
                            {attachmentUrl && (
                                <img src={attachmentUrl} alt="Uploaded" style={{ width: 100, marginTop: 10 }} />
                            )}
                        </Form.Item>
                    </Spin>

                );
            case 'DOCUMENT':
                return (
                    <Spin spinning={documentloading}>
                        <Form.Item label="Header File">
                            <Upload
                                beforeUpload={(file) => {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        form.setFieldsValue({ attachment: e.target.result });
                                        updatePreview({ ...form.getFieldsValue(), attachment: e.target.result });
                                    };
                                    reader.readAsDataURL(file);
                                    return true;
                                }}
                                customRequest={({ file, onSuccess, onError }) =>
                                    handleImageUpload(file, onSuccess, onError)
                                }
                                disabled={isDisabled}
                                listType="text"
                                maxCount={1}
                                accept=".pdf"
                            >
                                <Button icon={<UploadOutlined />}>Upload File</Button>
                            </Upload>
                            {attachmentUrl && (
                                <a href={attachmentUrl} target="_blank" rel="noopener noreferrer">
                                    View Uploaded File
                                </a>
                            )}
                        </Form.Item>
                    </Spin>
                );
            case 'VIDEO':
                return (
                    <Spin spinning={documentloading}>
                        <Form.Item label="Header Video">
                            <Upload
                                beforeUpload={(file) => {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        form.setFieldsValue({ attachment: e.target.result });
                                        updatePreview({ ...form.getFieldsValue(), attachment: e.target.result });
                                    };
                                    reader.readAsDataURL(file);
                                    return true;
                                }}
                                customRequest={({ file, onSuccess, onError }) =>
                                    handleImageUpload(file, onSuccess, onError)
                                }
                                disabled={isDisabled}
                                listType="text"
                                maxCount={1}
                                accept=".mp4,.3gp"            >
                                <Button icon={<UploadOutlined />}>Upload Video</Button>
                            </Upload>
                            {attachmentUrl && (
                                <video width="200" controls style={{ marginTop: 10 }}>
                                    <source src={attachmentUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </Form.Item>
                    </Spin>
                );
            default:
                return null;
        }
    };

    const handleCategorySelect = (pro_id) => {
        setSelectCate(pro_id)
    }

    const handleHeaderTypeSelect = (value) => {
        if (value) {
            form.setFieldsValue({
                header_type: value,
            });
        }
    }

    const handleSelectChange = (value, index) => {
        setWhatsappPlaceholder(prev => {
            const newArray = [...prev];
            newArray[index] = { type: value, text: "" };  // Store type & text
            return newArray;
        });
    };

    const handleTextChange = (e, index) => {
        setWhatsappPlaceholder(prev => {
            const newArray = [...prev];
            if (newArray[index]?.type === "text") {
                newArray[index].text = e.target.value;
            }
            return newArray;
        });
    };

    useEffect(() => { console.log("whatsaplssswwcaholder", whatsapp_placeholer) }, [whatsapp_placeholer])

    return (
        <>
            <Spin spinning={isLoading}>
                <Row gutter={24} className="p-4">
                    <Col span={14}>
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={(changedValues, allValues) => {
                                updatePreview(allValues);
                                if (!isProgrammaticUpdate) {
                                    const modifiedFields = Object.keys(changedValues);
                                    const trackableFields = ['templateName', 'subject', 'body', 'footer', 'headerText', 'text', 'footerText', 'header_type', 'category'];
                                    if (modifiedFields.some(field => trackableFields.includes(field))) {
                                        setHasFormBeenModified(true);
                                    }
                                }
                            }}
                        >
                            <Card className="mb-4">
                                <Form.Item
                                    name="name"
                                    label="Template Name"
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder="Enter template name" disabled={isDisabled} />
                                </Form.Item>
                                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginBottom: 5, fontSize: 13 }}>Select Provider</label>
                                    <Form.Item
                                        name="provider_id"
                                        rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Provider"
                                            style={{ width: "100%" }}
                                            value={selectedProviderId || ''}
                                            disabled={isDisabled}
                                            onChange={(valuee) => {
                                                setSelectedProviderId(valuee);
                                            }}
                                        >
                                            {Array.isArray(whatsappSettingData) && whatsappSettingData?.length !== 0 && whatsappSettingData?.map((setting) => (
                                                <Option key={setting.id} value={setting.id}>
                                                    {setting.sender_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    name="category"
                                    label="Select Category"
                                    rules={[{ required: true, message: 'Please select a Category' }]}
                                >
                                    <Select
                                        placeholder="Select a Category"
                                        onChange={handleCategorySelect}
                                        disabled={isDisabled}
                                    >
                                        <Option value="UTILITY">Utility</Option>
                                        <Option value="AUTHENTICATION">Authentication</Option>
                                        <Option value="MARKETING">Marketing</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="header_type"
                                    label="Select Header Type"
                                    rules={[{ required: true, message: 'Please select a Header Type' }]}
                                >
                                    <Select
                                        placeholder="Select a Header Type"
                                        disabled={isDisabled}
                                        onChange={handleHeaderTypeSelect}
                                    >
                                        <Option value="TEXT">Text header</Option>
                                        <Option value="IMAGE">Image header</Option>
                                        <Option value="DOCUMENT">file header</Option>
                                        <Option value="VIDEO">video header</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="channelType" label="Channel Type" hidden>
                                    <Input disabled />
                                </Form.Item>

                                <Form.Item name="subject" label="Subject" hidden>
                                    <Input disabled />
                                </Form.Item>

                                <Form.Item name="subjectId" label="Subject ID" hidden>
                                    <Input disabled />
                                </Form.Item>
                            </Card>

                            <Card title="Message Content" className="mb-4">

                                {renderHeaderFields()}

                                <Form.Item
                                    name="text"
                                    label="Body Text"
                                    rules={[{ required: true }]}
                                >
                                    <TextArea
                                        rows={4}
                                        placeholder="Example: Hello, {{1}}! Thanks for choosing {{2}}"
                                        disabled={isDisabled}
                                    />
                                </Form.Item>

                                <Form.Item name="footerText" label="Footer Text">
                                    <Input placeholder="Add a footer note" disabled={isDisabled} />
                                </Form.Item>
                            </Card>

                            <Card title="Buttons" className="mb-4">
                                <div className="grid grid-cols-12 gap-2 mb-4 d-flex justify-content-center">
                                    {buttonTypes.map(({ key, icon, label }) => (
                                        <Button
                                            key={key}
                                            onClick={() => addButton(key)}
                                            className="text-left grid-cols-12 flex items-center m-1 gap-4"
                                            disabled={isDisabled || buttons.length >= 3}
                                            style={{ width: '45%' }}
                                        >
                                            {icon}
                                            <span className="pl-4">{label}</span>
                                        </Button>
                                    ))}
                                </div>

                                <Space direction="vertical" className="w-full">
                                    {buttons.map((button) => (
                                        <Card
                                            key={button.id}
                                            size="small"
                                            className="w-full"
                                            title={button.type === 'URL' ? "Link" : "Phone"}
                                            extra={
                                                <Button
                                                    type="text"
                                                    onClick={() => removeButton(button.id)}
                                                    className="p-0"
                                                    disabled={isDisabled}
                                                >
                                                    <X className="h-4 w-4" />
                                                </Button>
                                            }
                                        >
                                            <div className="space-y-4">
                                                <Input
                                                    placeholder="Button Text"
                                                    value={button.text}
                                                    onChange={(e) => updateButtonField(button.id, 'text', e.target.value)}
                                                    disabled={isDisabled}

                                                />

                                                <Input
                                                    placeholder={button.type === 'URL' ? 'Website URL' : 'Phone Number'}
                                                    value={button.value}
                                                    onChange={(e) => updateButtonField(button.id, 'value', e.target.value)}
                                                    disabled={isDisabled}
                                                    className='mt-2'
                                                    type={button.type === 'URL' ? 'url' : 'text'}
                                                    requir
                                                />
                                            </div>
                                        </Card>
                                    ))}
                                </Space>
                            </Card>
                        </Form>
                    </Col>

                    <Col span={10}>
                        <Card title="Template Preview">
                            <WhatsAppPreview
                                preview={preview}
                                buttons={buttons}
                                path={urlsenderTemplates?.path ? urlsenderTemplates?.path : EditActivityTemplate?.header?.headerExampleMediaUrl || ''} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
            <Modal
                title="Enter Variable Values"
                open={isVariableModalOpen}
                onOk={handleVariableModalOk}
                onCancel={() => setIsVariableModalOpen(false)}
                destroyOnClose={false}
                maskClosable={false}
            >
                <div className="row">
                    <div className="col-4">
                        {Array.isArray(EditActivityTemplate?.body?.match(placeholderRegex)) &&
                            EditActivityTemplate?.body?.match(placeholderRegex)?.map((variable, index) => (
                                <div key={index} className="mb-4" style={{ textAlign: "center" }}>
                                    {variable}
                                </div>
                            ))}
                    </div>

                    <div className="col-8">
                        {Array.isArray(EditActivityTemplate?.body?.match(placeholderRegex)) &&
                            EditActivityTemplate?.body?.match(placeholderRegex)?.map((variable, index) => (
                                <div key={index} className="mb-3" style={{ display: "flex", justifyContent: "space-around", gap: 10 }}>
                                    <Select
                                        placeholder="Select a Category"
                                        value={whatsapp_placeholer[index]?.type || ""}
                                        onChange={(e) => handleSelectChange(e, index)}
                                        style={{ width: "100%" }}
                                    >
                                        <Option value="_name">_name</Option>
                                        <Option value="_email">_email</Option>
                                        <Option value="_phone">_phone</Option>
                                        <Option value="text">text</Option>
                                    </Select>

                                    {whatsapp_placeholer[index]?.type === "text" && (
                                        <Input
                                            placeholder="Enter your text"
                                            value={whatsapp_placeholer[index]?.text || ""}
                                            onChange={(e) => handleTextChange(e, index)}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default EditActivityWhatsappTemplate;

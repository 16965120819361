import { POST_ALL_URLS } from "../../constant/ActionType";

export default function UrlReducer(
  state = {
    urlsenderTemplates: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case POST_ALL_URLS:
      return {
        ...state,
        urlsenderTemplates: action,
        statusCode: action.status,
      };

    default:
  }

  return state;
}

import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Pagination, Button, Modal, Descriptions } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { dispatch } from "store/index";
import { allOfflineCallsColumns } from "components/columns/columns";
import { EditOutlined } from "@ant-design/icons";
import { FetchAllLeads, FetchOfflineCallsData, CreateBulkUploader } from "store/action/index";
import { onPageChange } from "helper/paginationHelper";
import { useNavigate } from "react-router-dom";

const OflineCalls = ({ allofflinecall }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [params, setparams] = useState({
    page: 1
  });

  const columns = allOfflineCallsColumns({ params, setparams });

  // columns.push(true ? {
  //   title: "Actions",
  //   key: "actions",
  //   render: (text, record) => {
  //     return (
  //       <span>
  //         {true ? (<IconButton
  //           className="mx-2"
  //           type="primary"
  //           onClick={() => {
  //             navigate("/offline/add-offline", { state: record });
  //           }}
  //         >
  //           <EditOutlined />
  //         </IconButton>) : <></>}
  //       </span>
  //     );
  //   },
  // } : <></>);

  useEffect(() => {
    setLoading(true)
    dispatch(FetchOfflineCallsData({ params: params, setLoading: setLoading }));
  }, [params])

  const expandedRowRender = (record) => {
    return (
      <div>
        <Descriptions title="Question" bordered column={1}>
          <Descriptions.Item label={record?.question}>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Answer" style={{ marginTop: "20px" }} bordered column={1}>
          <Descriptions.Item label={record?.answer}>
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  return (
    <>

      {/*<div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  All Offline Calls
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              {true ? (<Grid item>
                <Button
                  disableElevation
                  className="px-2"
                  size="large"
                  type="submit"
                  onClick={showModal}
                >
                  <span c lassName="mx-2">
                    <IoMdAddCircle color="#0055A2" size={26} />
                  </span>
                  Upload CSV
                </Button>
              </Grid>) : <></>}
              {true ? (<Grid item>
                <Link to={"/offline/add-offline"}>
                  <Button
                    disableElevation
                    // className="px-2"
                    size="large"
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoMdAddCircle color="#0055A2" size={26} />
                    </span>
                    Add Offline Call
                  </Button>
                </Link>
              </Grid>) : <></>}
            </Grid>
          </div>
        </MainCard>
      </div> */}
      <MainCard>

        <Table
          columns={columns}
          expandable={{
            expandedRowRender
          }}
          dataSource={allofflinecall?.data?.data ?? []}

          pagination={false}
          loading={loading}
          rowKey="id"
        />
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>

          <Pagination
            total={allofflinecall?.data?.total} // Use actual total count
            current={params?.page}
            pageSize={allofflinecall?.data?.per_page || 10}
            showSizeChanger={false}
            onChange={(page) => onPageChange(setparams, params, page)}
          />
        </div>
      </MainCard>
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    allofflinecall: state.ALLViewCallOffline.allofflinecall,
  };
};

export default connect(mapStateToProps)(OflineCalls);
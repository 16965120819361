import { CSV_ALL_CALL_LOGS} from "../../constant/ActionType";

export default function GetCallLogsCsvReducer(
    state = {
        callogscsvdataaa: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case CSV_ALL_CALL_LOGS:
            return {
                ...state,
                callogscsvdataaa: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}

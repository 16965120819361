import dashboard from "./dashboard";
import roles from "./roles";
import campaigns from "./campaigns";
import allAssignPermissions from "./allAssignPermissions";
import users from "./allusers";
import leadsMenu from "./leads";
import tagsMenu from "./tags";
import OmniCHannel from "./omnichannel";
import templates from "./templates";
import setting from "./settings";
import teamsMenu from "./teams";
import callscriptMenu from "./callscript";
import allLogs from "./allLogs";
import offlineCalls from "./allOfflineCalls";
const menuItems = {
  items: [
    ...(dashboard ? [dashboard] : []),
    ...(campaigns ? [campaigns] : []),
    ...(templates ? [templates] : []),
    ...(setting ? [setting] : []),
    ...(teamsMenu ? [teamsMenu] : []),
    ...(OmniCHannel ? [OmniCHannel] : []),
    ...(callscriptMenu ? [callscriptMenu] : []),
    ...(leadsMenu ? [leadsMenu] : []),
    ...(tagsMenu ? [tagsMenu] : []),
    ...(allLogs ? [allLogs] : []),
    // ...(offlineCalls ? [offlineCalls] : []),
    // ...(roles ? [roles] : []),
    ...(allAssignPermissions ? [allAssignPermissions] : []),
    ...(users ? [users] : []),
  ].filter(Boolean),
};

export default menuItems;

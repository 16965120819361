export const GET_ALL_MANAGEMENT = "GET_ALL_MANAGEMENT";
export const ALL_ASSIGN_PERMISSIONS = "ALL_ASSIGN_PERMISSIONS";
export const CREATE_ADD_USERS = "CREATE_ADD_USERS";
export const EDIT_UPDATE_USERS = "EDIT_UPDATE_USERS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const FETCH_LOGIN_DATA = "FETCH_LOGIN_DATA";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_STATUS_USERS = "UPDATE_STATUS_USERS";
export const VIEW_USERS = "VIEW_USERS";
export const VIEW_ROLES = "VIEW_ROLES";
export const ASSIGN_PERMISSIONS = "ASSIGN_PERMISSIONS";
export const GETALL_PERMISSION = "GETALL_PERMISSION";
export const ROLE_PERMISSIONS = "ROLE_PERMISSIONS";
export const EDIT_USER = "EDIT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const All_COUNTS = "All_COUNTS";
export const ALL_COMPAIGNS = "ALL_COMPAIGNS";
export const CREATE_COMPAIGNS = "CREATE_COMPAIGNS";
export const ALL_LEADS = "ALL_LEADS";
export const ALL_LEADS_SELECT = "ALL_LEADS_SELECT";
export const CREATE_LEADS = "CREATE_LEADS";
export const ALL_TAGS = "ALL_TAGS";
export const CREATE_TAGS = "CREATE_TAGS";
export const ALL_ACTIVITES = "ALL_ACTIVITES";
export const LEADS_DETAILS = "LEADS_DETAILS";
export const UPDATE_LEADS = "UPDATE_LEADS";
export const TAGS_EDIT = "TAGS_EDIT";
export const ALL_EMAIL_TEMPLATES = "ALL_EMAIL_TEMPLATES";
export const ADD_EMAIL_TEMPLATE = "ADD_EMAIL_TEMPLATE";
export const ALL_WHATSAPP_TEMPLATES = "ALL_WHATSAPP_TEMPLATES";
export const EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
export const CREATE_WHATSAPP_TEMPLATE = "CREATE_WHATSAPP_TEMPLATE";
export const CREATE_EMAIL_SETTING = "CREATE_EMAIL_SETTING";
export const UPDATE_EMAIL_SETTING = "UPDATE_EMAIL_SETTING";
export const ALL_EMAIL_SETTING = "ALL_EMAIL_SETTING";
export const ALL_CALL_SETTING = "ALL_CALL_SETTING";
export const CREATE_CALL_SETTING = "CREATE_CALL_SETTING";
export const UPDATE_CALL_SETTING = "UPDATE_CALL_SETTING";
export const CREATE_WHATSAPP_SETTING = "CREATE_WHATSAPP_SETTING";
export const ADD_NEW_WHATSAPP_SETTING = "ADD_NEW_WHATSAPP_SETTING";
export const ALL_SMS_SETTING = "ALL_SMS_SETTING";
export const CREATE_SMS_SETTING = "CREATE_SMS_SETTING";
export const UPDATE_SMS_SETTING = "UPDATE_SMS_SETTING";
export const ALL_TEAMS = "ALL_TEAMS";
export const ADD_TEAMS = "ADD_TEAMS";
export const UPDATE_TEAMS = "UPDATE_TEAMS";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const ALL_CAMP_ACTIVITY = "ALL_CAMP_ACTIVITY";
export const ALL_CALL_SCRIPT = "ALL_CALL_SCRIPT";
export const CREATE_CALL_SCRIPT = "CREATE_CALL_SCRIPT";
export const UPDATE_CALL_SCRIPT = "UPDATE_CALL_SCRIPT";
export const ALL_CALL_LOGS = "ALL_CALL_LOGS";
export const GET_ALL_LOGS = "GET_ALL_LOGS";
export const ALL_SMS_LOGS = "ALL_SMS_LOGS";
export const ALL_EMAIL_LOGS = "ALL_EMAIL_LOGS";
export const ALL_WHATSAPP_LOGS = "ALL_WHATSAPP_LOGS";
export const ALL_LEADS_CALL_LOGS = "ALL_LEADS_CALL_LOGS";
export const ALL_SMS_TEMPLATES = "ALL_SMS_TEMPLATES";
export const ADD_LEADS_ACTIVITY = "ADD_LEADS_ACTIVITY";
export const CALL_ACTIVITY = "CALL_ACTIVITY";
export const CALL_LEAD_ACTIVITY = "CALL_LEAD_ACTIVITY";
export const FETCH_LEAD_ACTIVITY_DATA = "FETCH_LEAD_ACTIVITY_DATA";
export const ACTIVITY_LEAD_NOTES = "ACTIVITY_LEAD_NOTES";
export const CREATE_ACTIVITY_LEAD_NOTES = "CREATE_ACTIVITY_LEAD_NOTES";
export const EDIT_ACTIVITY = "EDIT_ACTIVITY";
export const ACTIVITY_PROCESS = "ACTIVITY_PROCESS";
export const ACTIVITY_DETAILS = "ACTIVITY_DETAILS";
export const CREATE_SMS_TEMPLATES = "CREATE_SMS_TEMPLATES";
export const EDIT_SMS_TEMPLATES = "EDIT_SMS_TEMPLATES";
export const WHATSAPP_CHANNEL_GET = "WHATSAPP_CHANNEL_GET";
export const CASCADES_GET = "CASCADES_GET";
export const LEAD_HISTORY = "LEAD_HISTORY";
export const FETCH_CHANNELS = "FETCH_CHANNELS";
export const FETCH_CASCADES = "FETCH_CASCADES";
export const FETCH_TEMPLATEWHATSAPP_SUBJECTID = "FETCH_TEMPLATEWHATSAPP_SUBJECTID";
export const CALL_ACTIVITY_CALL_LOG = "CALL_ACTIVITY_CALL_LOG";
export const DASHBOARD_COUNTS = "DASHBOARD_COUNTS";
export const EMAIL_SENT_OMNICHANNEL = "EMAIL_SENT_OMNICHANNEL";
export const GET_FRESH_CAMPAIGN_LEADS = "GET_FRESH_CAMPAIGN_LEADS";
export const CALL_MANAGER_DATA = "CALL_MANAGER_DATA";
export const OFFLINE_CALLS_GET = "OFFLINE_CALLS_GET";
export const CREATEONE_OFFLINE_CALLS = "CREATEONE_OFFLINE_CALLS";
export const EDIT_OFFLINE_CALLS = "EDIT_OFFLINE_CALLS";
export const CREATE_BULK_UPLOAD = "CREATE_BULK_UPLOAD";
export const CHECK_SESSION_ON_USERS = "CHECK_SESSION_ON_USERS";
export const CSV_ALL_CALL_LOGS = "CSV_ALL_CALL_LOGS";

export const POST_ALL_URLS = "POST_ALL_URLS";
export const ALL_CAMPAIGN_REPORT = "ALL_CAMPAIGN_REPORT";

export const ALL_CAMPAIGN_EXPORT_DATA = "ALL_CAMPAIGN_EXPORT_DATA";
export const ALL_LEADS_REPORTS = "ALL_LEADS_REPORTS";
export const LOGS_ACTIVITY_STATS = "LOGS_ACTIVITY_STATS";




import { ALL_CAMPAIGN_EXPORT_DATA} from "../../constant/ActionType";

export default function CampActivtyExportdataaRedc(
    state = {
        campaignActExpData: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case ALL_CAMPAIGN_EXPORT_DATA:
            return {
                ...state,
                campaignActExpData: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}

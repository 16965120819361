import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
import { IoCallSharp } from "react-icons/io5";
const icons = {
  navigation: MdHome, // General Navigation icon
  allLeads: IoCallSharp, // Icon for All Leads
  addLead: HiOutlineUserAdd, // Icon for Adding a Lead
};

const offlineCalls =
  true
    ? {
      id: "call-offline",
      title: "Offline Calls",
      icon: icons.allLeads,
      type: "group",
      children: [
        (true) && {
          id: "all-leads",
          title: "Offline Calls",
          type: "item",
          url: "/offline/offline-calls",
          icon: icons.allLeads,
          breadcrumbs: true,
        },
      ].filter(Boolean),
    }
    : null;

export default offlineCalls;

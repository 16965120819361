import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Descriptions, Empty, Pagination } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { dispatch } from "store/index";
import { callsLogColumns } from "components/columns/columns";
import { AllLogsCsvExport, FetchGetAllLogs } from "store/action/index";
import AudioPlayer from '../../components/inputs/AudioPlayer'
import * as url from "../../store/constant/Endpoints";
import { CiExport } from "react-icons/ci";
import * as XLSX from "xlsx";


const CallLogs = ({ alllogsdataaa, callogscsvdataaa }) => {
    const [loading, setLoading] = useState(true);
    const [params, setparams] = useState({ page: 1, activity_id: 2 });
    const [paramss, setparamss] = useState({ per_page: "all", activity_id: 2 });
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        dispatch(AllLogsCsvExport({ paramss: paramss }));
    }, [])

    useEffect(() => {
        setLoading(true)
        dispatch(FetchGetAllLogs({ params: params, setLoading: setLoading }));
    }, [params]);


    useEffect(() => {
        console.log("csvExport", callogscsvdataaa)
    }, [callogscsvdataaa]);

    const columns = callsLogColumns({ params, setparams });
    columns.push({
        title: "Call Recording",
        render: (text, record) => {
            const rec = Array.isArray(record?.call_log) && record?.call_log?.length ? record.call_log?.filter((log) => log?.download_path) : [];
            return rec.at(-1) ? <AudioPlayer audioUrl={url.audio_url + rec?.at(-1)?.download_path} /> : <></>;
        },
    })
    const handlePageChange = (page) => {
        setparams({ ...params, page });
    };

    useEffect(() => {
        if (!alllogsdataaa?.data || !Array.isArray(alllogsdataaa.data)) {
            setDataSource([]);
            return;
        }

        const ReadyToFormatView = alllogsdataaa.data.map((log) => ({
            ...log,
            questions: log.questions?.map((question) => ({
                question: question?.question,
                answers: question?.call_answer?.length === 1
                    ? question?.call_answer[0]?.answer
                    : question?.call_answer?.map((answer) => answer?.answer),
            })),
        }));

        setDataSource(ReadyToFormatView);
        console.log("AllDatatataat", alllogsdataaa)
    }, [alllogsdataaa]);

    const expandedRowRender = (record) => {
        return (
            <div>
                <Descriptions title="Call Answers" bordered column={1}>
                    {Array.isArray(record.call_answer) && record.call_answer.length ? record.call_answer.map((item) => (
                        <Descriptions.Item key={item.id} label={item.question.question}>
                            {(() => {
                                try {
                                    if (item.answer.includes('undefined')) {
                                        return 'No Answer'
                                    }
                                    const parsedAnswer = JSON.parse(item.answer);
                                    return Array.isArray(parsedAnswer)
                                        ? parsedAnswer.join(', ')
                                        : parsedAnswer;
                                } catch {
                                    return item.answer;
                                }
                            })()}
                        </Descriptions.Item>
                    )) : <Empty />}
                </Descriptions>
                <Descriptions title="Call Logs" bordered column={1}>
                    {Array.isArray(record.call_log) && record.call_log.length ? record.call_log.map((log) => (
                        <Descriptions.Item key={log.id} >
                            <div>
                                <div>
                                    <strong>Status:</strong> {log.call_status}
                                </div>
                                {log.call_status !== 'Terminated' && <div>
                                    < strong > Started On:</strong> {log.call_started_on}
                                </div>}
                                <div>
                                    <strong>Ended On:</strong> {log.call_ended_on}
                                </div>
                                <div>
                                    {log?.download_path && (
                                        <div>
                                            <strong>Call Recording:</strong>
                                            <AudioPlayer audioUrl={url.audio_url + log?.download_path} />
                                        </div>
                                    )}


                                </div>
                            </div>
                        </Descriptions.Item>
                    )) : <Empty />}
                </Descriptions>
            </div>
        );
    };



    const exportExcel = () => {
        const formattedData = (Array.isArray(callogscsvdataaa) ? callogscsvdataaa : []).map((log) => ({
            "Campaign Name": log?.campaign?.name || "",
            "Activity Name": log?.activity?.name || "",
            "Activity Action": log?.activity_action || "",
            "Lead Name": log?.lead?.name || "",
            "Lead Email": log?.lead?.email || "",
            "Lead Phone": log?.lead?.phone || "",
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CallLogs");
        XLSX.writeFile(workbook, "CallLogs.xlsx");
    };


    return (
        <>
            <MainCard>
                <div
                    style={{ display: "flex", justifyContent: "right", padding: "10px" }}
                >
                    <Button
                        disableElevation
                        className="px-2"
                        size="large"
                        type="submit"
                        onClick={exportExcel}
                    >
                        <span className="mx-2">
                            <CiExport color="#0055A2" size={26} />
                        </span>
                        Export Excel
                    </Button>
                </div>
                <div style={{ overflowX: "auto" }}>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender
                        }}
                        dataSource={dataSource ?? []}

                        pagination={false}
                        loading={loading}
                        rowKey="id"
                    />
                </div>
                <div style={{ textAlign: "center", marginTop: 16 }}>
                    <Pagination
                        current={alllogsdataaa?.current_page || 1}
                        total={alllogsdataaa?.total || 0}
                        pageSize={alllogsdataaa?.per_page || 10}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                    />
                </div>
            </MainCard>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        alllogsdataaa: state?.GetAllLogsReducer?.alllogsdataaa,
        callogscsvdataaa: state?.GetCallLogsCsvReducer?.callogscsvdataaa,
    };
};

export default connect(mapStateToProps)(CallLogs);